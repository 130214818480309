
:root {
	--main-bg-color: #ddd;
	--main-text-color: #111;

	--accent-1a: #ffc217;
	--accent-1b: #111;
	--accent-1c: #b9ae90;

	--accent-2a: #FFD96F;
	--accent-2b: #222;
}


/**
	Basics
 **/
	html, body, #root {
		font-size: 12pt;
		height: 100%;
	}

	.App {
		display: flex;
		min-height: 100%;
	}

	.page {
		text-align: center;
		padding: 1em;
	}

	.page>*{
		text-align: initial;
	}

	h1, h2, h3, h4{
		margin: .25em 0 0 0;
	}

	h1 {
		font-size: 32pt !important;
	}

	.page h2 {
		text-align: center;
		font-size: 28pt
	}

	.page .back-button{
		display: block;
		position: absolute;
		top: 1.75em;
		width: 1em;
		height: 1em;
		border-radius: 50%;
		border-width: .125em;
		line-height: 1em;
	}

	details>summary{
		cursor: pointer;
	}

	.error-card .card-header {
		background-color: #c00;
		color: #fff;
	}
	.error-card details {
		margin-left: 2em;
	}
	.error-card summary {
		margin-left: -2em;
	}

	.message-card .card-header {
		background-color: #c60;
		color: #fff;
	}
	.message-card details {
		margin-left: 2em;
	}
	.message-card summary {
		margin-left: -2em;
	}

	.disabled {
		pointer-events : none;
		filter: grayscale(100%) blur(0.75px) brightness(85%);
	}


/**
	Modals
 **/
	.modal {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 5em 0;

		background-color: #3337;
		text-align: center;
		z-index: 100;
	}

	.modal .modal-dialog {
		display: inline-block;
		margin: auto;
		background: #fff;
		border-radius: .125em;
		padding: .25em 1em;
		z-index: 101;
	}

	.modal .modal-header {
		border-bottom: 1px solid #ddd;
		margin: 0;
		padding: .25em .5em;
		font-size: 16pt;
		font-weight: bold;
	}

	.modal .modal-body {
		margin: .5em;
	}

	.modal .modal-footer {
		border-top: 1px solid #ddd;
		padding: .5em;
		text-align: right;
	}

/**
	Sidebar
 **/

	.sidebar {
		background: #333;
		color: #aaa;
		height: 100%;
		width: 14em;
		padding: 0.5em;
		position: fixed;
		min-height: 100%;
		transition: left .5s, margin .5s;
		overflow: auto;
	}
	.sidebar::after {
		content: '';
		margin: 1em;
	}

	.sidebar.hidden {
		margin-left: -14.5em;
		overflow: hidden;
	}

	.sidebar>header {
		text-align: center;
		color: var(--accent-1a);
		border-bottom: 4px solid #f00;
		padding-bottom: 1em;
		margin-bottom: .5em;
	}

	.sidebar h1 {
		margin: 0;
	}

	.sidebar nav a {
		color: var(--accent-1a);
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-decoration: none;
		padding: .5em 1em;
	}

	.sidebar nav a:hover {
		background: #444;
	}

	.sidebar .section-header {
		border-top: 1px solid #f00;
		font-weight: bold;
		padding-top: 0.25em;
		margin-top: .5em;
	}

	.sidebar .event-link {
		margin-left: 1em;
		padding-top: .1em;
		padding-bottom: .1em;
	}

	.sidebar-toggle {
		position: fixed;
		bottom: 1em;
		cursor: pointer;
		font-weight: bold;
		padding: 0 .75em .4em;
		transition: right .75s;
	}
	.sidebar-toggle>div {
		font-size: 20pt;
	}

	.sidebar-toggle.left {
		background-color: #333;
		color: var(--accent-1a);
		border-radius: 0 1em 1em 0;
	}

	.sidebar-toggle.right {
		right: 0;
		border-radius: 1em 0 0 1em;

		background: var(--main-bg-color);
		color: #333;
	}

	.sidebar-toggle.left.available {
		display: block;
	}

	.sidebar div.uid {
		margin-top: 12em;
		font-size: 9pt;
		font-family: monospace;
	}


/**
	Content
 **/

	.content {
		margin-left: 15em;
		flex: 1;
		background: var(--main-bg-color);
		z-index: 1;
		transition: margin .5s;
		padding-bottom: 3em;
	}

	.content.full {
		margin-left: 0.5em;
	}



/**
	Forms
 **/

	.form-control {
		width: 100%;
		border: 1px solid #444;
		padding: 0.25em;
		border-radius: 0.4em;
	}

	label>input {
		display: block;
	}

	label>input[type=file] {
		display: none;
	}

	.responsive-form {
		display: grid;
		grid-template-columns: auto auto;
		grid-gap: 0.25em;
	}

	.responsive-form>*{
		padding: 0.25em;
	}

	.responsive-form>*:nth-child(odd) {
		text-align: right;
		font-weight: bold;
	}

	.responsive-form>*:nth-child(odd):hover,
	.responsive-form>*:nth-child(odd):hover + *{
			background-color: #e8e8e8;
	}

	.responsive-form.tight {
		grid-gap: 0;
	}

	.responsive-form.table>*:nth-child(1),
	.responsive-form.table>*:nth-child(2) {
		font-weight: bold;
		background-color: #ddd;
	}

	.table>* {
		border: 1px solid #ccc;
	}

	/* Buttons */
		.btn {
			box-shadow: .125em .125em .125em #aaa;
			margin: 0.25em 0.25em;
			border-radius: .25em;
			padding: .5em;
			cursor: pointer;
			border: .25em outset;
			text-decoration: none;
			display: inline-block;
			text-align: center;
			background-origin: padding-box;
			vertical-align: middle;
		}

		.btn:disabled {
			opacity: .25;
		}

		.btn:hover {
			box-shadow: .25em .25em .25em #777;
		}

		.btn-primary {
			font-weight: bold;
			background: var(--accent-1a);
			border-color: var(--accent-2a);
			color: var(--accent-1b);
		}

		.btn-secondary {
			background: var(--accent-2a);
			border-color: #fff;
			color: var(--accent-1b);
		}

		.btn-link,
		.btn-danger {
			color: inherit;
			background-color: inherit;
			border: none;
			padding: .75em 1em;
		}

		.btn-danger:hover {
			background: #f00;
			color: #fff;
		}

		.btn-sm {
			padding: 0 0.25em;
			margin: 0.125em;
			font-size: 10pt;
			box-shadow: none;
		}

		.btn-sm:hover {
			box-shadow: .125em .125em .125em #aaa
		}

		.clickable {
			cursor: pointer;
		}

		.page>.button-container {
			position: fixed;
			display: inline-block;
			right: 1em;
			bottom: .5em;
			border-radius: .25em;
			padding: .3em;
			background: #333;
			color: #fff;
		}

		.page>.button-container .btn {
			box-shadow: none;
			cursor: pointer;
			height: 1.5em;
			border-width: .125em;
			padding: 0 .25em;
			font-size: 10pt;
		}


	/* Radio groups */
		.radioGroup {
		}

		.radioGroupChoice {
			display: inline-block;
			padding-left: 0.5em;
			padding-right: 0.5em;
			border-radius: 0.25em;
			border: 1px solid var(--accent-2a);
			margin: 0 0.125em;
			cursor: pointer;
		}

		.radioGroupChoice:hover {
			background-color: var(--accent-2a);
			color: var(--accent-2b);
		}

		.radioGroupChoice.selected{
			background-color: var(--accent-1a);
			color: var(--accent-1b);
			font-weight: bold;
		}

		.enableDisable .radioGroupChoice-Enabled.selected {
			background-color: var(--accent-1a);
			color: var(--accent-1b);
		}

		.enableDisable .radioGroupChoice-Disabled.selected {
			background-color: #dc3545;
			color: #eee;
		}

	/* Progress bar */
		.progressBar {
			text-align: center !important;
			position: relative;
			font-weight: bold;
			text-shadow: 0.033em 0.033em .033em #ddd;
			background-color: var(--main-bg-color);
			border-radius: .33em;
			z-index: 1;
			margin: 0.125em 0;
		}

		.progressBarFill {
			border-radius: .33em;
			background-color: var(--accent-1a) !important;
			position: absolute;
			height: 100%;
			z-index: -1;
		}

/**
	Section cards
 **/

	.section-card {
		margin: 1em;
		border-radius: 4px;
		border: 1px solid #aaa;
		background-color: #fff;
	}

	.section-card.squeeze {
		display: inline-block;
		margin: auto;
	}

	.section-card>*{
		border-radius: inherit;
	}

	.card-header {
		background-color: var(--accent-1a);
		color: var(--accent-2b);
		padding: .25em 1em;
		font-size: 18pt;
	}

	.card-header h4 {
		margin: 0;
	}

	.card-header>.button-container {
		float: right;
		margin-top: 0;
		margin-right: -.5em;
	}

	.card-header>.button-container .btn {
		border-width: .125em;
		padding: .25em;
		font-size: 10pt;
	}

	.card-header>.button-container .btn-primary {
		background-color: var(--accent-2a);
	}

	.card-header>.button-container .btn-secondary {
		background-color: var(--accent-1a);
	}

	.card-body {
		padding: 1em;
	}


/**
	Environment ribbon
 **/

	.corner-ribbon{
		width: 300px;
		position: fixed;
		top: 50px;
		left: -75px;
		line-height: 25px;
		letter-spacing: 1px;
		transform: rotate(-45deg);
		text-align: center;
		font-weight: bold;
		background: #34e;
		color: #f0f0f0;
		border: 3px solid #ecc;
		z-index: 99;
	}

	.corner-ribbon.staging {
		background: #e43;
	}


/**
	Big ol button
 **/

	.big-ol-button-container {
		display: flex;
		justify-content: space-evenly;
		flex-wrap: wrap;
	}

	.big-ol-button-container>.big-ol-button {
		margin: 0.25em;
	}

	.big-ol-button {
		font-weight: bold;
		font-size: 18pt;
		min-width: 8em;
		background-color: #ffd96f;
		border-radius: 0.5em;
		border-width: 2px;
		border-color: #777;
		box-shadow: .125em .125em .125em #aaa;
		cursor: pointer;
		text-align: center;

	}

	.big-ol-button:hover {
		box-shadow: .25em .25em .25em #777;
	}

	.big-ol-button>.button-middle {
		font-size: 32pt;
	}
	.big-ol-button>* {
		padding: 0 0.25em;
	}



	details>* {
		margin-left: 1em;
	}
	details>summary {
		margin-left: 0;
	}
	details>ul {
		margin: 0;
		margin-left: -.5em;
	}


/**
	Mobile
 **/
	@media only screen and (max-width: 768px) {
		html, body, .App {
			height: 100%;
			overflow: hidden;
			background: #333;
		}
		.sidebar {
			z-index: 3;
			text-align: center;
			width: 100%;
		}

		.sidebar .event-link {
			margin: auto;
		}

		.sidebar.hidden {
			margin-left: -100%;
			margin-right: 100%;
		}

		.sidebar-toggle.right {
			display: block;
			position: fixed;
		}

		.sidebar.hidden .sidebar-toggle.right{
			right: -50%;
		}

		.content {
			margin-left: .5em;
			overflow-y: auto;
		}

		.content.full {
			height: 100%;
		}
	}
